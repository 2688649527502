import {Component, HostListener, OnInit} from '@angular/core';
import { ScrollerService } from '../../services/scroller.service';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})


export class NavigationComponent implements OnInit {

  constructor(private scrollerService: ScrollerService) { }

  currentPos: number;
  breakpoints = {
    phone: 640,
    tablet: 768,
    desktop: 1024
  };

  headerIsCollapsed = false;
  deviceIsPhone = window.innerWidth <= this.breakpoints.phone;

  menu = [
    {
      menuname: 'Rólunk',
      scrollTo: 'section-rolunk'
    }, {
      menuname: 'Szolgáltatás',
      scrollTo: 'section-szolgaltatas'
    }, {
      menuname: 'Géppark',
      scrollTo: 'section-geppark'
    },  {
      menuname: 'Árak',
      scrollTo: 'section-araink'
    }, {
      menuname: 'Referencia',
      scrollTo: 'section-referencia'
    }, {
      menuname: 'Kapcsolat',
      scrollTo: 'section-kapcsolat'
    }];

    scrollToSection(section): void {
      const element = $('#' + section);
      const elementHeight = element.height();
      const spaceLeft = window.innerHeight - elementHeight;
      const scrollToCenter = elementHeight < window.innerHeight - 50;
      const extraNumber = scrollToCenter ? Math.round((spaceLeft - 50) / 2) - 50 : 50;

      this.scrollerService.triggerScrollTo(section, extraNumber);
    }

    toggleMenu() {
      const hamburger = $('.hamburger--button');
      const navitems = $('.navitem--section');
      if (this.deviceIsPhone) {
        if (hamburger.hasClass( 'open' )) {
          hamburger.removeClass('open');
          navitems.removeClass('open');
        } else {
          hamburger.addClass('open');
          navitems.addClass('open');
        }
      }
    }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.headerIsCollapsed = window.pageYOffset >= 220;
    this.currentPos = window.scrollY;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.deviceIsPhone = window.innerWidth <= this.breakpoints.phone;
  }


  ngOnInit() {
  }

}
