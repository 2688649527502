import { Injectable } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Injectable({
  providedIn: 'root'
})
export class ScrollerService {

  constructor(private scrollToService: ScrollToService) { }

  public triggerScrollTo(dest, extraOffset) {

    const config: ScrollToConfigOptions = {
      target: dest,
      offset: -extraOffset
    };

    this.scrollToService.scrollTo(config);
  }
}
