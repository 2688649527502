import { Component, OnInit } from '@angular/core';
declare let L;

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  mapSize: object = {
    width: window.innerWidth,
    height: 300
  };
  breakpoints = {
    phone: 640,
    tablet: 768,
    desktop: 1024
  };

  deviceIsPhone = window.innerWidth <= this.breakpoints.phone;

  imageSizeParams: object = {
    width: this.deviceIsPhone ? window.innerWidth : window.innerWidth * 0.2,
    height: window.innerHeight * 0.3
  };
  imageObject: Array<object> = [
  {
      image: 'assets/images/40t_Liebherr_autodaru.jpg',
      thumbImage: '../../assets/images/40t_Liebherr_autodaru.jpg',
      alt: '40t Liebherr autódaru',
      title: '40t Liebherr autódaru',
  }, {
    image: '../../assets/images/40t_Liebherr_autodaru_2.jpg',
    thumbImage: '../../assets/images/40t_Liebherr_autodaru_2.jpg',
    alt: '40t Liebherr autódaru',
    title: '40t Liebherr autódaru',
  },
    {
      image: '../../assets/images/CCCD6 típusú 5t oldalvillás targonca.jpg',
      thumbImage: '../../assets/images/CCCD6 típusú 5t oldalvillás targonca.jpg',
      alt: 'CCCD6 típusú 5t oldalvillás targonca.jpg',
      title: 'CCCD6 típusú 5t oldalvillás targonca'
    }, {
      image: '../../assets/images/Manitou MRT 1640.jpg',
      thumbImage: '../../assets/images/Manitou MRT 1640.jpg',
      alt: 'Manitou MRT 1640',
      title: 'Manitou MRT 1640'
    }, {
      image: '../../assets/images/Manitou MRT 1640 2.jpg',
      thumbImage: '../../assets/images/Manitou MRT 1640 2.jpg',
      alt: 'Manitou MRT 1640',
      title: 'Manitou MRT 1640'
    }, {
      image: '../../assets/images/Manitou MRT 2540.jpg',
      thumbImage: '../../assets/images/Manitou MRT 2540.jpg',
      alt: 'Manitou MRT 2540',
      title: 'Manitou MRT 2540'
    }, {
      image: '../../assets/images/Manitou MRT 2540_2.jpg',
      thumbImage: '../../assets/images/Manitou MRT 2540_2.jpg',
      alt: 'Manitou MRT 2540',
      title: 'Manitou MRT 2540'
    }, {
      image: '../../assets/images/Manitou MRT 2540_3.jpg',
      thumbImage: '../../assets/images/Manitou MRT 2540_3.jpg',
      alt: 'Manitou MRT 2540',
      title: 'Manitou MRT 2540'
    }, {
      image: '../../assets/images/Scania trailer.jpg',
      thumbImage: '../../assets/images/Scania trailer.jpg',
      alt: 'Scania trailer',
      title: 'Scania trailer'
    },
  ];


    projektek = [
    ['AirPort City raktár csarnok', 'Vecsés'],
      ['Neuzer Fejlesztőközpont', 'Esztergom'],
      ['Görgőspálya fogadó épület és silók', 'Gyermely tésztagyár'],
      ['Coca Cola folyamatos csarnok és kiszolgáló épület bővítése', 'Dunaharaszti'],
      ['Szerelő csarnok', 'Tamási'],
      ['Teva csarnok bővítése', 'Gödöllő'],
      ['Zoltek új és meglévő szerelő csarnok építése', 'Nyergesújfalu'],
      ['Budapest- Szolnok vonalon vasúti híd építése', 'Gödöllő'],
      ['Nagy értékű gépek mozgatása', 'Semilab Zrt Budapest'],
      ['MTK Stadion', 'Budapest'],
      ['Puskás Ferenc Stadion', 'Budapest'],
      ['Várkert Bazár', 'Budapest']
  ];



  megbizok = [
    'Aktuál Bau Kft',
    'Archimax Kft',
    'Arco Művek Kft',
    'ASA Építőipari Kft',
    'Egomax Kft',
    'Első Beton Kft',
    'Ferrobeton Zrt',
    'Guriga Kft',
    'Merkbau Kft',
    'Strabag Általános Építő Kft',
    'Swietelsky Magyarország Kft',
    'VIV Zrt.',
    'ZÁÉV Zrt.'
  ];

  constructor() { }

  ngOnInit() {
    const map = L.map('map').setView([47.491, 19.262], 15);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
  }

}
